<template>
    <div class="container-fluid p-0">
        <section class="feature-title">
            <div
                style="background-image: url(https://static.vitaalumnicn.com/static/vsweb/life-at-vita-bg.png);"
                class="feature-title-background"></div>
        </section>
        <section class="vita-search">
            <div class="mt-0 pt-0 container p-l-50 p-r-50">
                <h1 class="title-line mb-0">BEGIN BIG</h1>
                <div class="">{{ $t('lifeAtVita.studentExperience') }}</div>
                <div class="link cursor" @click="$goto('/find-your-room')">
                    <div class="view-all">{{ $t('common.generalLp') }}</div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>
            </div>
        </section>

        <section class="banner-img">
            <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-1-1.png"
                 alt="">
        </section>

        <section class="vita-video">
            <div class="common-header">
                <div class="common-title font-size-26">
                    {{ $t('lifeAtVita.whatToExpect') }}
                </div>
                <div class="common-note" style="max-width: 355px">
                    {{ $t('lifeAtVita.whatToExpectNote') }}
                </div>
            </div>
            <div class="video-area">
                <video title="VS Experience Page Lower Res" preload="none" loop="" width="100%" height="100%"
                       frameborder="0" muted="" playsinline="" autoplay="">
                    <source src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-1-1.mp4"
                            type="video/mp4">
                </video>
            </div>
        </section>

        <section class="media-carousels">
            <div class="common-header">
                <div class="common-title">
                    {{ $t('lifeAtVita.whatIncluded') }}
                </div>
                <div class="common-note">
                    {{ $t('lifeAtVita.whatIncludedNote') }}
                </div>
            </div>
            <div class="container">
                <div v-for="(item, index) of carousels">
                    <div v-if="index%2 === 0" class="item d-flex justify-content-center">
                        <div class="img-slide">
                            <div class="red-line-area d-flex justify-content-end align-items-end position-left">
                                <div class="red-line"></div>
                            </div>
                            <div class="carousel-container">
                                <swiper :options="swiperOption" ref="lifeAtVitaSwiper">
                                    <swiper-slide v-for="(image, ind) in item.urls" :key="ind">
                                        <img class="img" :src="image" alt=""/>
                                    </swiper-slide>
                                    <div class="swiper-pagination" slot="pagination"></div>
                                </swiper>
                            </div>
                        </div>
                        <div class="info-area cursor">
                            <div class="info">
                                <div :class="$i18n.locale === 'cn' ? 'info-title-cn' : 'info-title'">{{ item.title }}</div>
                                <div class="info-note"> {{ item.note }}</div>
                            </div>
                            <div class="d-flex">
                                <div class="arrow-btn me-1 icon iconfont" @click="prevSlide(index)">&#xe64c;</div>
                                <div class="arrow-btn  icon iconfont" @click="nextSlide(index)">&#xe64d;</div>
                            </div>
                        </div>
                    </div>


                    <div v-if="index%2 === 1" class="item d-flex justify-content-center">
                        <div class="info-area cursor">
                            <div class="info">
                                <div :class="$i18n.locale === 'cn' ? 'info-title-cn' : 'info-title'">{{ item.title }}</div>
                                <div class="info-note"> {{ item.note }}</div>
                            </div>
                            <div class="d-flex justify-content-end">
                                <div class="arrow-btn me-1 icon iconfont" @click="prevSlide(index)">&#xe64c;</div>
                                <div class="arrow-btn  icon iconfont" @click="nextSlide(index)">&#xe64d;</div>
                            </div>
                        </div>

                        <div class="img-slide">
                            <div class="red-line-area d-flex justify-content-end align-items-end position-right">
                                <div class="red-line"></div>
                            </div>
                            <div class="carousel-container">
                                <swiper :options="swiperOption" ref="lifeAtVitaSwiper">
                                    <swiper-slide v-for="(image, ind) in item.urls" :key="ind">
                                        <img class="img" :src="image" alt=""/>
                                    </swiper-slide>
                                    <div class="swiper-pagination" slot="pagination"></div>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <div class="link cursor" @click="$goto('/find-your-room')">
                    <div class="view-all">{{ $t('common.generalLp') }}</div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>
            </div>
        </section>

        <section class="vita-cities row">
            <div class="city-left col-3">
                <div class="left-content">
                    <div class="title-line" :style="$i18n.locale === 'cn' ? { 'line-height': '1.2' } : ''">
                        {{ $t('home.startHere') }} <br>
                        {{ $t('home.goAnywhere') }}
                    </div>
                    <div class="title-note mt-1">
                        {{ $t('home.stayIn') }}
                    </div>
                    <div class="link mt-3 cursor" @click="$goto('/cities')">
                        <div class= "view-all-cities">{{ $t('home.viewAllCities') }}</div>
                        <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                    </div>
                </div>
            </div>
            <div class="col-9 p-l-45">
                <div class="city-right">
                    <div class="scroll-container custom-scroll p-b-36" ref="scrollContainer1">
                        <div class="item cursor"
                             @click="$goto('/cities/' + city.slug)"
                             v-for="(city, index) of lifeCityList"
                             :key="index">
                            <div class="item-title">
                                <span>{{ city.name }}</span>
                            </div>
                            <img decoding="async" loading="lazy" :src="city.img">
                        </div>
                        <div class="left-right-btn">
                            <div class="btn-arrow cursor icon iconfont" @click="handleOffset('left', 1)"> &#xe64c;</div>
                            <div class="btn-arrow cursor icon iconfont" @click="handleOffset('right', 1)"> &#xe64d;</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="all-living">
            <div class="common-header">
                <div class="common-title font-size-26">
                    {{ $t('lifeAtVita.unrivalledRooms') }}
                </div>
                <div class="common-note font-size-16" style="width: 306px">
                    {{ $t('lifeAtVita.unrivalledRoomsNote') }}
                </div>
            </div>
        </section>

        <section class="all-living-imgs container-fluid pt-0">
            <picture>
                <img decoding="async" loading="lazy"
                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-3-1.png">
            </picture>
            <picture>
                <img decoding="async" loading="lazy"
                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-3-2.png">
            </picture>
            <picture>
                <img decoding="async" loading="lazy"
                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-3-3.png">
            </picture>
            <picture>
                <img decoding="async" loading="lazy"
                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-3-4.png">
            </picture>
        </section>

        <section class="featured-posts">
            <div class="common-header">
                <div class="common-title-black" v-html="$t('lifeAtVita.knowEverything')"></div>
            </div>
            <div class="container-fluid p-0 m-0">
                <div class="imgs row">
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-1.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    {{ $t('lifeAtVita.movingToVitaStudent') }}
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel" @click="$goto('/international-students')">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-2.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    {{ $t('lifeAtVita.internationalStudents') }}
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel" @click="$goto('/parents')">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-3.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    {{ $t('lifeAtVita.parents') }}
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-4.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    {{ $t('lifeAtVita.howCanSoftSkillsImproveMyCV') }}
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <our-students class="section-black" :ourStudentsNote="ourStudentsNote"></our-students>

        <section class="banner-img">
            <img class="footer-img"
                 src="https://www.vitastudent.com/wp-content/uploads/2023/05/Balcony-optimised-v2.jpg" alt="">
        </section>

    </div>
</template>

<script>

import ourStudents from '@/components/our-students'
import {getCitiesAPI} from '@/apis/common'

export default {
    name: "life-at-vita",
    components: {
        ourStudents
    },
    data() {
        return {
            cities: [],
            swiperOption: {
                pagination: false,
                autoplay: false,
                loop: true
            },
        }
    },
    computed: {
        ourStudentsNote: function () {
            return this.$t('lifeAtVita.ourStudentsNote')
        },
        carousels() {
            return [
                {
                    id: 1,
                    title: this.$t('lifeAtVita.bestFacilities'),
                    note: this.$t('lifeAtVita.bestFacilitiesNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-5.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-6.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-7.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-8.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-9.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-10.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-11.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-12.png'
                    ]
                },
                {
                    id: 2,
                    title: this.$t('lifeAtVita.service'),
                    note: this.$t('lifeAtVita.serviceNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-5.png'
                    ]
                },
                {
                    id: 3,
                    title: this.$t('lifeAtVita.worldFamousEvents'),
                    note: this.$t('lifeAtVita.worldFamousEventsNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-5.png'
                    ]
                },
                {
                    id: 4,
                    title: this.$t('lifeAtVita.ultimateLocations'),
                    note: this.$t('lifeAtVita.ultimateLocationsNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-5.png'
                    ]
                },
                {
                    id: 5,
                    title: this.$t('lifeAtVita.careerOpportunities'),
                    note: this.$t('lifeAtVita.careerOpportunitiesNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-5.png'
                    ]
                },
                {
                    id: 6,
                    title: this.$t('lifeAtVita.freeHousekeeping'),
                    note: this.$t('lifeAtVita.freeHousekeepingNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-4.png'
                    ]
                }
            ]
        },
        lifeCityList: function (){
            let isCn = this.$i18n.locale === 'cn'
            let arr = ['barcelona','leeds','york','coventry','nottingham','birmingham','liverpool','edinburgh','manchester','sheffield']
            const list = []
            this.cities.forEach(rest => {
                list.push({
                    id: rest.id,
                    img: rest.thumbnailUrl,
                    slug: rest.slug,
                    name: isCn ? rest.name : rest.enName,
                })
            })
            const cities = []
            arr.forEach(rest => {
                let city = list.find(item => {
                    return item.slug === rest
                })
                if (city) {
                    cities.push(city)
                }
            })
            return cities
        },
    },
    created() {
        this.getCities()
    },
    methods: {
        async getCities() {
            if (this.$store.state.cities.length > 0) {
                this.cities = this.$store.state.cities;
            } else {
                await getCitiesAPI({showApartments: 1}).then(res => {
                    if (res.status === 200) {
                        this.cities = res.data.data
                        this.$store.commit('setCities', this.cities)
                    }
                })
            }
        },
        prevSlide(index) {
            this.$refs.lifeAtVitaSwiper[index].swiper.slidePrev();
        },
        nextSlide(index) {
            this.$refs.lifeAtVitaSwiper[index].swiper.slideNext();
        },
        handleOffset(offset, type = null) {
            let scrollContainer = this.$refs['scrollContainer' + type];
            if (offset === 'left') {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft - 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
                if (type === '2') {

                }
            } else {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft + 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
                if (type === '2') {

                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "./index.less";

.carousel-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

</style>
